<template>
  <div class="notFound">
    <div class="error-container d-flex flex-column justify-center align-center">
      <p class="white--text">404 Page Not Found</p>
      <p class="white--text" v-if="!loggedIn">
        Take me back to <router-link to="/login">Login Page</router-link>
      </p>
      <p class="white--text" v-else-if="loggedIn && roleName === 'Staff'">
        Take me back to
        <router-link to="/receipt-scanner">Receipt Scanner Page</router-link>
      </p>
      <p class="white--text" v-else-if="loggedIn && roleName === 'Admin'">
        Take me back to <router-link to="/admin">Admin Page</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      loggedIn: (state) => state.auth.loggedIn,
      roleName: (state) => state.auth.roleName,
    }),
  },
};
</script>

<style lang="scss" scoped>
.error-container {
  text-align: center;
  height: 100vh;
}

.error-container > p:first-child {
  font-size: 100px;
}
.error-container > p:last-child {
  font-size: 50px;
}

@media screen and (max-width: 640px) {
  .error-container > p:first-child {
    font-size: 50px;
  }
  .error-container > p:last-child {
    font-size: 25px;
  }
}
</style>
